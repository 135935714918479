import React from "react"

import TopHeader from './top-header';
import ContentHeader from './content-header';

import '../../../styles/layout/header/header.less';

export default class Header extends React.Component
{
  render()
  {
    return <header className={this.props.className}>
      <TopHeader />
      <ContentHeader className={this.props.className} businessUp={this.props.businessUp} />
    </header>
  }
}
