import React from "react";

import SocialTopNetwork from './social-top-network';
import { faFacebook, faTwitter, faLinkedin, faInstagram } from '@fortawesome/free-brands-svg-icons'

const SocialsTop = () => (
    <ul className="socials-top">
        <SocialTopNetwork icon={faFacebook} link="https://www.facebook.com/AdventureesGO" network="facebook" />
        <SocialTopNetwork icon={faTwitter} link="https://twitter.com/AdventureesGO" network="twitter" />
        <SocialTopNetwork icon={faLinkedin} link="https://www.linkedin.com/company/adventurees/" network="linkedin" />
        <SocialTopNetwork icon={faInstagram} link="https://www.instagram.com/adventurees.go/" network="Instagram" />
    </ul>
)

export default SocialsTop;
