import React from "react"
import HeaderMenuItem from './header-menu-item';
import AccessBox from "./access-box";
import params from "../../../data/params";

class HeaderMenu extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state =
        {
            productsMenu:
            [
                {
                    text: 'BusinessUp',
                    url: '/businessup'
                },
                {
                    text: 'Crowdfunding',
                    url: '/products',
                    params: {activeTab: 'crowdfunding'}
                },
                {
                    text: 'Goldsmith® Toolkit',
                    url: '/products',
                    params: {activeTab: 'toolkit'}
                }
            ],
            servicesMenu:
            [
                {
                    text: 'Training',
                    url: '/services',
                },
                {
                    text: 'Mentoring',
                    url: '/services',
                },
                {
                    text: 'Consulting',
                    url: '/services',
                },
                {
                    text: 'Investment',
                    url: '/services'
                }
            ],
            investmentMenu:
            [
                {
                    text: 'Investment platform',
                    url: '/investment'
                },
                {
                    text: 'Investment clubs',
                    url: '/investment#investment-clubs'
                },
                {
                    text: 'Demo Days',
                    url: '/investment#investment-clubs'
                },
                {
                    text: 'Fintech Forum',
                    url: '/investment'
                }
            ]
        }
    }
    toggleHamburgerMenu()
    {
        var body_element = document.body;
        var menu_element = document.getElementsByClassName('main-menu')[0];
        var hamburger_element = document.getElementsByClassName('c-hamburger')[0];

        // Closing menu
        if (body_element.classList.contains('menu-open'))
        {
            body_element.classList.remove('menu-open');
            menu_element.classList.remove('open');
            hamburger_element.classList.remove('is-active');
        }

        // Opening menu
        else
        {
            body_element.classList.add('menu-open');
            menu_element.classList.add('open');
            hamburger_element.classList.add('is-active');
        }
    }

    render()
    {
        return <div className="right-header">
            <nav className="main-menu">
                <button className="c-hamburger c-hamburger--htx" onClick={() => this.toggleHamburgerMenu()}><span></span></button>
                {!this.props.businessUp && !this.props.businessUpDev &&
                    <ul>
                        <HeaderMenuItem label={"Products"} link="/products" submenu={this.state.productsMenu} />
                        <HeaderMenuItem label={"Services"} link="/services" />
                        <HeaderMenuItem label={"Investment"} link="/investment" />
                        <HeaderMenuItem label={"Clients"}  link="/clients" />
                        <HeaderMenuItem label={"News"}  link="/news" />
                        <HeaderMenuItem label={"Contact"}  link="/contact" />
                    </ul>
                }
                {this.props.businessUp &&
                    <ul className="business-up-menu">
                        <HeaderMenuItem label={"BusinessUp"} link="/businessup" />
                        { false && <HeaderMenuItem label={"Product Tour"} link="/businessup/product-tour" />}
                        <HeaderMenuItem label={"Pricing"} link="/businessup/pricing" />
                        <HeaderMenuItem label={"Testimonials"} link="/businessup/testimonials" />
                        <HeaderMenuItem label={"FAQs"} link="/businessup/faqs" />
                        <HeaderMenuItem className="d-inline-block d-sm-none d-md-none button" isExternal={true} label={"Free trial"} link={params.DO_CREATE_URL} />
                    </ul>
                }

                {this.props.businessUpDev &&
                    <ul className="business-up-menu">
                        <HeaderMenuItem label={"BusinessUp"} link="/business-up-dev" />
                        { false && <HeaderMenuItem label={"Product Tour"} link="/businessup/product-tour" />}
                        <HeaderMenuItem label={"Pricing"} link="/businessup/pricing" />
                        <HeaderMenuItem label={"Testimonials"} link="/businessup/testimonials" />
                        <HeaderMenuItem label={"FAQs"} link="/businessup/faqs" />
                        <HeaderMenuItem className="d-inline-block d-sm-none d-md-none button" isExternal={true} label={"Free trial"} link={params.DEV_DO_CREATE_URL} />

                    </ul>
                }
            </nav>
            <AccessBox businessUp={this.props.businessUp} businessUpDev={this.props.businessUpDev} />
        </div>;
    }
}

export default HeaderMenu;
