import React from "react"
import LanguageSwitcher from "./languageSwitcher";
import params from "../../../data/params";

const TopHeaderMenu = () => (
  <div className="top-header-menu">
    <div className="phone">
        <a href="tel://+34-91-193-00-61">+34 91 193 00 61</a>
    </div>
    <div className="email">
        <a href={"mailto:"+params.CONTACT_EMAIL}>
            {params.CONTACT_EMAIL}
        </a>
    </div>
    <LanguageSwitcher />
  </div>
)

export default TopHeaderMenu;
