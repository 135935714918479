import React from "react";
import { injectIntl, Link } from 'gatsby-plugin-intl';

const HeaderMenuItem = (props) => {
	const closeMenu = () =>
	{
		var body_element = document.body;
		var menu_element = document.getElementsByClassName('main-menu')[0];
        var hamburger_element = document.getElementsByClassName('c-hamburger')[0];
		
		if (body_element.classList.contains('menu-open'))
        {
            body_element.classList.remove('menu-open');
            menu_element.classList.remove('open');
            hamburger_element.classList.remove('is-active');
        }
	}
  	return <li className="dropdown" onClick={() => closeMenu()}>
		  	{props.isExternal && <a target="_blank" rel="noopener noreferrer" href={props.link} className={props.className}>{props.intl.formatMessage({id: props.label})}</a>}
          	{!props.isExternal && <Link to={props.link} activeClassName="active">{props.intl.formatMessage({id: props.label})}</Link>}
          	{props.submenu && 
          	<ul className="sub-menu">
				  {props.submenu.map((item, index) => {
					  return <li key={index}>
							<Link
								to={item.url}
								state={item.params}
								>
									{props.intl.formatMessage({id: item.text})}
							</Link>
					  	</li>
				  })}
          	</ul>}
        </li>
}

export default injectIntl(HeaderMenuItem);
