import React from 'react';
import { changeLocale, injectIntl } from 'gatsby-plugin-intl';

class LanguageSwitcher extends React.Component
{
  constructor(props)
  {
    super(props);
    this.changeLocale = this.changeLocale.bind(this);

  }
  changeLocale(language)
  {
    changeLocale(language);
  }

  render()
  {
    return <>
      {['es', 'pt', 'en'].map((language, index) => {
        if (language !== this.props.intl.locale)
        {
          return <a key={index} className="flag" onClick={() => this.changeLocale(language)}>
            <img src={"/images/common/flag-"+language+'.png'}  alt={language}/>
          </a>
        }
        
        return null;
      })}
    </>
  }
}
  

export default injectIntl(LanguageSwitcher);