import React from "react"
import { injectIntl } from 'gatsby-plugin-intl';

import params from "../../../data/params";

class AccessBox extends React.Component
{
    render()
    {
        if (this.props.businessUp)
        {
            return <div className="access-box">
                <a className="corporate-button d-none d-sm-inline-block" rel="noopener noreferrer" href={params.DO_CREATE_URL}>{this.props.intl.formatMessage({id: "Free trial"})}</a>

                <a className="d-none d-sm-inline-block" rel="noopener noreferrer" href={params.DO_HOME_URL}>{this.props.intl.formatMessage({id: "Client Area"})}</a>
                <a className="d-inline-block d-sm-none d-md-none" rel="noopener noreferrer" href={params.DO_HOME_URL}>{this.props.intl.formatMessage({id: "Access"})}</a>
            </div>
        }
        else if (this.props.businessUpDev)
        {
            return <div className="access-box">
                <a className="corporate-button d-none d-sm-inline-block" rel="noopener noreferrer" href={params.DEV_DO_CREATE_URL}>{this.props.intl.formatMessage({id: "Free trial"})}</a>

                <a className="d-none d-sm-inline-block" rel="noopener noreferrer" href={params.DEV_DO_HOME_URL}>{this.props.intl.formatMessage({id: "Client Area"})}</a>
                <a className="d-inline-block d-sm-none d-md-none" rel="noopener noreferrer" href={params.DEV_DO_HOME_URL}>{this.props.intl.formatMessage({id: "Access"})}</a>
            </div>
        }
        else {
            return <div className="access-box">
                <a className="d-none d-sm-inline-block" rel="noopener noreferrer" href={params.DO_HOME_URL}>{this.props.intl.formatMessage({id: "Client Area"})}</a>
                <a className="d-inline-block d-sm-none d-md-none" rel="noopener noreferrer" href={params.DO_HOME_URL}>{this.props.intl.formatMessage({id: "Access"})}</a>
            </div>;
        }
    }
}

export default injectIntl(AccessBox);
