import React from "react";
import PropTypes from "prop-types";

import Header from "./header/header";
import HeaderOffset from './header/header-offset';
import Footer from "../footer";
import FooterNew from "../footerNew";

import 'bootstrap/dist/css/bootstrap.css';
import "../../styles/layout/layout.less";

/**
 * This component is responsible for the layout of the website. It handles:
 * Header
 * Header offset
 * Footer
 * 
 * props:
 *       -'staticHeader'. If passed (true), the header won't be drawn transparent when scrolled to the top of the page. (It won't register the event listener either)
 *                        otherwise, the header will switch between transparent and white depending on the scroll.
 *       -'test'. If passed (true), the page will use the new footer. (This param should be removed once the footer is validated and applied to the whole website)
 *       -'businessUp'. If passed (true), the header menu will change to display the businessUp menu entries (testimonials, faqs, pricing...)
 */


class Layout extends React.Component
{
  constructor(props)
  {
    super(props);

    let headerClassName = 'site-header';
    let offsetClassName = '';

    if (props.staticHeader)
    {
      headerClassName += ' fixed';
      offsetClassName += 'active-offset';
    }

    this.state = {headerClassName: headerClassName, offsetClassName: offsetClassName};

    this.handleScroll = this.handleScroll.bind(this);
  }

  // This life-cycle function is called after the component has rendered and is ready (It is called only once)
  componentDidMount()
  {
    if (!this.props.staticHeader)
    {
      window.addEventListener('scroll', this.handleScroll, {passive: true});
    }
  }

  //This life-cycle method is called just before the component will be killed. In this specific case it is not really necessary, because the layour should always be present, but it's not bad to be cautious. 
  componentWillUnmount()
  {
      window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll()
  {
    const vscroll = window.pageYOffset; 

    if (vscroll === 0)
    {
      this.setState(
        {
          headerClassName: 'site-header',
          offsetClassName: '',
        }
      );
    }
    else
    {
      this.setState(
        {
          headerClassName: 'site-header fixed',
          offsetClassName: 'active-offset'
        }
      );
    }
  }

  /** This method is called when the component is first instantiated
   * Whenever its parent sends new props to it
   * Whenever this component updates its state through 'this.setState' method.
   */
  render()
  {
    return <>
      <Header className={this.state.headerClassName} businessUp={this.props.businessUp} />
      <HeaderOffset activeOffset={this.state.offsetClassName} />
      <div>
        <main className={this.props.className}>{this.props.children}</main>
      </div>
      <FooterNew />
    </>
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  staticHeader: PropTypes.bool
}

export default Layout;
