import React from "react";

import Layout from "../components/layout/layout";
import SEO from "../components/seo";
import Section from "../components/section";
import params from "../data/params";

const LegalAdvice = (props) => {
    return <Layout staticHeader>
        <SEO title="Aviso Legal" description="" location={props.location}/>

        <Section title="Aviso Legal">
            <div className="container" style={{marginBottom: "45px"}}>
                <h3>1.- Derecho de la informaci&oacute;n</h3>
                <p>Le informamos de que este Sitio Web es titularidad de ADVENTUREES ALLIANCE, S.L. (en adelante, &ldquo;<strong><em>ADVENTUREES ALLIANCE</em></strong>&rdquo;), con N.I.F. B-76712066 y domicilio social en la Calle Santa Teresa de Jornet e Ibars, 11, 38004, Santa Cruz de Tenerife, Espa&ntilde;a.</p>
                <p>El acceso y/o uso al Sitio Web le atribuye la condici&oacute;n de usuario, y acepta, desde dicho acceso y/o uso, el presente Aviso Legal.</p>
                <p>El usuario (en adelante, el &ldquo;<strong><em>Usuario</em></strong>&rdquo;) puede ponerse en contacto con ADVENTUREES ALLIANCE a trav&eacute;s de la siguiente direcci&oacute;n de correo electr&oacute;nico: {params.CONTACT_EMAIL}.</p>
                <p>&nbsp;</p>
                <h3>2.- Uso de la Plataforma</h3>
                <p>El Usuario asume la responsabilidad del uso del Sitio Web. El Sitio Web puede proporcionar acceso a multitud de textos, gr&aacute;ficos, dibujos, dise&ntilde;os, fotograf&iacute;as, contenidos multimedia, e informaciones (en adelante, "<strong><em>Contenidos</em></strong>") pertenecientes a ADVENTUREES ALLIANCE o a terceros a los que el Usuario puede tener acceso.</p>
                <p>El Usuario se compromete a hacer un uso adecuado de los Contenidos y Servicios ofrecidos a trav&eacute;s del Sitio Web y con car&aacute;cter enunciativo, pero no limitativo, a no emplearlos para:</p>
                <ul>
                    <li>Incurrir en actividades il&iacute;citas, ilegales o contrarias a la buena fe y al orden p&uacute;blico.</li>
                </ul>
                <ul>
                    <li>Provocar da&ntilde;os en los sistemas f&iacute;sicos y l&oacute;gicos del Sitio Web de ADVENTUREES ALLIANCE, de sus proveedores o de terceras personas.</li>
                </ul>
                <ul>
                    <li>Introducir o difundir en la red virus inform&aacute;ticos o cualesquiera otros sistemas f&iacute;sicos o l&oacute;gicos que sean susceptibles de provocar los da&ntilde;os anteriormente mencionados.</li>
                </ul>
                <ul>
                    <li>Intentar acceder, utilizar y/o manipular los datos de ADVENTUREES ALLIANCE, terceros proveedores y otros Usuarios.</li>
                </ul>
                <ul>
                    <li>Reproducir o copiar, distribuir, permitir el acceso del p&uacute;blico a trav&eacute;s de cualquier modalidad de comunicaci&oacute;n p&uacute;blica, transformar o modificar los Contenidos, a menos que se cuente con la autorizaci&oacute;n de ADVENTUREES ALLIANCE.</li>
                </ul>
                <ul>
                    <li>Suprimir, ocultar o manipular los Contenidos sujetos a derechos de propiedad intelectual o industrial y dem&aacute;s datos identificativos de dichos derechos de ADVENTUREES ALLIANCE o de terceros incorporados a los Contenidos, as&iacute; como los dispositivos t&eacute;cnicos de protecci&oacute;n o cualesquiera mecanismos de informaci&oacute;n que puedan insertarse en los Contenidos.</li>
                </ul>
                <p>ADVENTUREES ALLIANCE advierte que los materiales contenidos en esta p&aacute;gina web han sido incluidos a mero t&iacute;tulo informativo, por lo que resultan insuficientes para tomar decisiones o asumir posiciones en un caso concreto.</p>
                <p>El usuario debe tener en cuenta que los materiales contenidos en esta p&aacute;gina web pudieran no reflejar el estado legislativo o jurisprudencial m&aacute;s reciente sobre las cuestiones analizadas. Asimismo, estos materiales pueden ser modificados, desarrollados o actualizados sin notificaci&oacute;n previa.</p>
                <p>ADVENTUREES ALLIANCE tendr&aacute; derecho a investigar y denunciar cualquiera de las conductas mencionadas de acuerdo con la Ley, as&iacute; como a colaborar con las autoridades en la investigaci&oacute;n de dichas actuaciones.</p>
                <p>ADVENTUREES ALLIANCE podr&aacute; suspender temporalmente y sin previo aviso, la accesibilidad al Sitio Web con motivo de operaciones de mantenimiento, reparaci&oacute;n, actualizaci&oacute;n o mejora. No obstante, siempre que las circunstancias lo permitan, ADVENTUREES ALLIANCE comunicar&aacute; al Usuario, con antelaci&oacute;n suficiente, la fecha prevista para la suspensi&oacute;n de los Servicios. ADVENTUREES ALLIANCE no se responsabiliza del uso que los Usuarios puedan hacer de los Contenidos incluidos en el Sitio Web.</p>
                <p>&nbsp;</p>
                <h3>3.- Propiedad Intelectual e Industrial</h3>
                <p>Todos los derechos de propiedad intelectual del Contenido de este Sitio Web y su dise&ntilde;o gr&aacute;fico son propiedad exclusiva de ADVENTUREES ALLIANCE, o bien de un tercero que ha autorizado la utilizaci&oacute;n de los mismos, por lo que es a ADVENTUREES ALLIANCE a quien le corresponde el ejercicio exclusivo de los derechos de explotaci&oacute;n de los mismos.</p>
                <p>Por ello y en virtud de lo dispuesto en la legislaci&oacute;n aplicable en materia de Derechos de Propiedad Intelectual e Industrial.</p>
                <p>ADVENTUREES ALLIANCE no concede ninguna licencia o autorizaci&oacute;n de uso de ninguna clase sobre sus derechos de propiedad intelectual e industrial o sobre cualquier otra propiedad o derecho relacionado con el Sitio Web, los Servicios o los Contenidos de este.</p>
                <p>Se permite la reproducci&oacute;n y almacenamiento temporal de los contenidos del sitio web en tanto ello sea estrictamente necesario para el uso y visualizaci&oacute;n del sitio web desde un ordenador personal.</p>
                <p>La legitimidad de los derechos de propiedad intelectual o industrial correspondientes a los Contenidos aportados por los Usuarios es responsabilidad exclusiva de los mismos, por lo que el Usuario mantendr&aacute; indemne a ADVENTUREES ALLIANCE de cualquier reclamaci&oacute;n de terceros derivada de la utilizaci&oacute;n il&iacute;cita de Contenidos en el Sitio Web.</p>
                <p>&nbsp;</p>
                <h3>4.- Responsabilidad y Garant&iacute;as</h3>
                <p>ADVENTUREES ALLIANCE declara que ha adoptado las medidas necesarias que, dentro de sus posibilidades y el estado de la tecnolog&iacute;a, permitan el correcto funcionamiento de su Sitio Web, as&iacute; como la ausencia de virus y componentes da&ntilde;inos. Sin embargo, ADVENTUREES ALLIANCE no puede hacerse responsable de:</p>
                <ul>
                    <li>La continuidad y disponibilidad de los Contenidos y Servicios.</li>
                </ul>
                <ul>
                    <li>La ausencia de errores en dichos Contenidos ni la correcci&oacute;n de cualquier defecto que pudiera ocurrir.</li>
                </ul>
                <ul>
                    <li>La ausencia de virus y/o dem&aacute;s componentes da&ntilde;inos.</li>
                </ul>
                <ul>
                    <li>Los da&ntilde;os o perjuicios que cause cualquier persona que vulnere los sistemas de seguridad de ADVENTUREES ALLIANCE.</li>
                </ul>
                <p>Las aportaciones que se pueden encontrar en estas p&aacute;ginas se han realizado &uacute;nicamente a t&iacute;tulo informativo. ADVENTUREES ALLIANCE no garantiza la integridad, exactitud y actualidad de sus contenidos. Por ello, no son v&aacute;lidas como asesoramiento jur&iacute;dico concreto en casos particulares. Las relaciones mandatarias tendr&aacute;n lugar &uacute;nicamente despu&eacute;s de la confirmaci&oacute;n por escrito.</p>
                <p>ADVENTUREES ALLIANCE no asume ninguna responsabilidad sobre los enlaces a otras p&aacute;ginas web que se encuentren en el Sitio Web, pudiendo dirigir al Usuario a otros sitios web sobre los que ADVENTUREES ALLIANCE no tiene ning&uacute;n tipo de control, por lo que la inclusi&oacute;n de v&iacute;nculos con otros sitios web no implica la aprobaci&oacute;n de sus contenidos por parte de ADVENTUREES ALLIANCE ni la existencia de ning&uacute;n tipo de asociaci&oacute;n entre ADVENTUREES ALLIANCE y sus titulares. Por ello el Usuario accede bajo su exclusiva responsabilidad al Contenido y en las condiciones de uso que rijan en los mismos.</p>
                <p>&nbsp;</p>
                <h3>5.- Duraci&oacute;n y modificaci&oacute;n</h3>
                <p>El presente Aviso Legal estar&aacute; vigente con car&aacute;cter indefinido, pudiendo ADVENTUREES ALLIANCE efectuar cambios sobre las condiciones especificadas en el mismo, y que entrar&aacute;n en vigor desde el momento de su publicaci&oacute;n.</p>
                <p>ADVENTUREES ALLIANCE podr&aacute; suprimir, a&ntilde;adir o cambiar tanto los Contenidos como los Servicios que presta, as&iacute; como tambi&eacute;n la forma en la que los mismos aparezcan localizados o presentados. Se entienden como vigentes, las condiciones que est&eacute;n publicadas en el momento en el que el Usuario acceda al Sitio Web de ADVENTUREES ALLIANCE.</p>
                <p>El acceso y/o el uso del Sitio Web se entender&aacute; como una aceptaci&oacute;n por parte del Usuario del presente Aviso Legal y sus condiciones y, en su caso, los cambios efectuados en las mismas.</p>
            </div>
        </Section>
    </Layout>
}

export default LegalAdvice;
