import React from "react"

import SiteBrand from './site-brand';
import HeaderMenu from './header-menu';

const ContentHeader = (props) =>
{
  return <div className="content-header">
    <div className="container">
        <SiteBrand className={props.className} />
        <HeaderMenu businessUp={props.businessUp} />
    </div>
  </div>
}

export default ContentHeader;
